<template>
  <div class="hold-transition">
    <div class="modal fade" id="modal-form-detalles">
      <div class="modal-dialog modal-xl p-0 pt-1" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Detalle del Servicio</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalDetalles"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-1">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Detalles"
                  data-toggle="tab"
                  href="#Detalles"
                  >Detalles</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Aprobaciones"
                  data-toggle="tab"
                  href="#Aprobaciones"
                  >Aprobaciones</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Tarifas"
                  data-toggle="tab"
                  href="#Tarifas"
                  >Tarifas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Mapa"
                  @click="getDatosMapa()"
                  data-toggle="tab"
                  href="#Mapa"
                  >Mapa</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade active show"
                id="Detalles"
                v-if="servicioInterno"
              >
                <!-- Detalles del servicio -->
                <div class="card-body text-muted text-xs">
                  <div class="row justify-content-center">
                    <div class="col-md-4">
                      <ul class="fa-ul mb-0">
                        <li>
                          <div class="row">
                            <div class="col-md-5"># Servicio:</div>
                            <div class="col-md-7 mb-1">
                              <strong>{{ servicioInterno.id }}</strong>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-5">Tipo Servicio:</div>
                            <div class="col-md-7 mb-1">
                              <strong>{{
                                servicioInterno.nTipoServicio
                              }}</strong>
                            </div>
                          </div>
                          <div class="row" v-if="servicioInterno.empresa">
                            <div class="col-md-5">Empresa:</div>
                            <div class="col-md-7 mb-1">
                              <strong>{{
                                servicioInterno.empresa.razon_social
                              }}</strong>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="
                              servicioInterno.postulacion &&
                                servicioInterno.postulacion
                                  .det_solicitud_interna
                            "
                          >
                            <div class="col-md-5">
                              Tipo
                              {{
                                servicioInterno.equipo_type == "App\\Vehiculo"
                                  ? "Vehículo"
                                  : "Equipo"
                              }}:
                            </div>
                            <div class="col-md-7 mb-1">
                              <strong>
                                {{
                                  servicioInterno.equipo_type == "App\\Vehiculo"
                                    ? servicioInterno.postulacion
                                        .det_solicitud_interna.nTipoVh
                                    : servicioInterno.postulacion
                                        .det_solicitud_interna.nTipoEqui
                                }}
                                {{
                                  servicioInterno.remolque
                                    ? " - " +
                                      servicioInterno.remolque.nTipoRemolque
                                    : ""
                                }}
                              </strong>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-5">
                              {{
                                servicioInterno.equipo_type == "App\\Vehiculo"
                                  ? "Vehículo"
                                  : "Equipo"
                              }}:
                            </div>
                            <div class="col-md-7 mb-1">
                              <strong>{{
                                servicioInterno.equipo
                                  ? servicioInterno.equipo.placa
                                  : ""
                              }}</strong>
                            </div>
                          </div>
                          <div class="row" v-if="servicioInterno.remolque">
                            <div class="col-md-5">Remolque:</div>
                            <div class="col-md-7 mb-1">
                              <strong>{{
                                servicioInterno.remolque.placa
                              }}</strong>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-4">
                      <ul class="fa-ul mb-0">
                        <li>
                          <div
                            class="row"
                            v-if="
                              servicioInterno.postulacion &&
                                servicioInterno.postulacion
                                  .det_solicitud_interna
                            "
                          >
                            <div class="col-md-5">Tipo Turno:</div>
                            <div class="col-md-7 mb-1">
                              <strong>{{
                                servicioInterno.postulacion
                                  .det_solicitud_interna.nTurno
                              }}</strong>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-5">
                              {{
                                servicioInterno.persona_type == "App\\Conductor"
                                  ? "Conductor"
                                  : "Operario"
                              }}:
                            </div>
                            <div class="col-md-7 mb-1">
                              <strong>{{
                                servicioInterno.persona
                                  ? `${servicioInterno.persona.nombres} ${servicioInterno.persona.apellidos}`
                                  : ""
                              }}</strong>
                            </div>
                          </div>
                          <div class="row" v-if="servicioInterno.aparejador">
                            <div class="col-md-5">Aparejador:</div>
                            <div class="col-md-7 mb-1">
                              <strong>{{
                                servicioInterno.aparejador
                                  ? `${servicioInterno.aparejador.nombres} ${servicioInterno.aparejador.apellidos}`
                                  : ""
                              }}</strong>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-5">Bloque:</div>
                            <div class="col-md-7 mb-1">
                              <strong>{{
                                servicioInterno.bloque
                                  ? servicioInterno.bloque.nombre
                                  : ""
                              }}</strong>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-5">Sitio:</div>
                            <div class="col-md-7 mb-1">
                              <strong>{{
                                servicioInterno.sitio
                                  ? servicioInterno.sitio.nombre
                                  : ""
                              }}</strong>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-5">Inicio Servicio:</div>
                            <div class="col-md-7 mb-1">
                              <strong>{{ servicioInterno.fecha_ini }}</strong>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-5">Fin Servicio:</div>
                            <div class="col-md-7 mb-1">
                              <strong>{{ servicioInterno.fecha_fin }}</strong>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-5">Estado:</div>
                            <div class="col-md-7 mb-1">
                              <span
                                class="badge"
                                :class="
                                  servicioInterno.estado == 1
                                    ? 'badge-secondary'
                                    : servicioInterno.estado == 2
                                    ? 'badge-warning'
                                    : servicioInterno.estado == 3
                                    ? 'bg-navy'
                                    : servicioInterno.estado == 4
                                    ? 'bg-orange'
                                    : servicioInterno.estado == 5
                                    ? 'badge-info'
                                    : servicioInterno.estado == 6
                                    ? 'badge-success'
                                    : 'bg-light'
                                "
                              >
                                {{ servicioInterno.nEstado }}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <!-- Información del Visto Bueno -->
                    <CsRevisionTarifasVtoBno ref="CsRevisionTarifasVtoBno" />

                    <!--  Card Trabajo Realizado -->
                    <div class="col-md-6 pt-3 mt-3">
                      <div class="card card-lightblue card-outline m-0">
                        <div class="card-header pt-2 pb-2">
                          <h3 class="card-title text-navy text-bold">
                            <i class="fas fa-check text-navy"></i>
                            Trabajo Realizado
                          </h3>
                        </div>
                        <div class="card-body">
                          <div class="col-sm-12 form-group clearfix">
                            <div class="row justify-content-center">
                              <div class="form-group col-md-12">
                                <p>{{ servicioInterno.trabajo_realizado }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--  Card Observación -->
                    <div class="col-md-6 pt-3 mt-3">
                      <div class="card card-lightblue card-outline m-0">
                        <div class="card-header pt-2 pb-2">
                          <h3 class="card-title text-bold text-navy">
                            <i class="fas fa-comment-alt text-navy"></i>
                            Observaciones
                          </h3>
                        </div>
                        <div class="card-body">
                          <div
                            class="col-sm-12 form-group clearfix"
                            v-if="servicioInterno.observacion"
                          >
                            <div class="row justify-content-center">
                              <div class="form-group col-md-12">
                                <p>
                                  {{ servicioInterno.observacion }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="alert alert-default-warning col-md-12"
                            v-else
                          >
                            <h5>
                              <i class="icon fas fa-exclamation-triangle"></i>
                              Atención!
                            </h5>
                            No se encontro observación.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Reporte de trabajos -->
                <div class="card-body pt-0 pb-0">
                  <CsRevisionReporteTrabajos ref="CsRevisionReporteTrabajos" />
                </div>
                <!-- Reporte de trabajos conductor 2 -->
                <div class="card-body pt-0 pb-0">
                  <CsRevisionDetalleSegundoConductor
                    ref="CsRevisionDetalleSegundoConductor"
                    v-if="conductor_2"
                  />
                </div>

                <!-- Cargos Contables -->
                <div class="card-body pt-0 pb-0">
                  <CsRevisionPresupuesto ref="CsRevisionPresupuesto" />
                </div>
              </div>
              <div class="tab-pane" id="Aprobaciones">
                <CsRevisionDetalleFirmas ref="CsRevisionDetalleFirmas" />
              </div>
              <div class="tab-pane" id="Tarifas">
                <div class="card-body pb-0">
                  <CsRevisionTarifasTrabajos ref="CsRevisionTarifasTrabajos" />
                  <CsRevisionTarifasAdicionales
                    ref="CsRevisionTarifasAdicionales"
                  />
                </div>
              </div>
              <div class="tab-pane" id="Mapa">
                <CsServicioMapa
                  v-if="servicio_id != null"
                  ref="CsServicioMapa"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import CsRevisionReporteTrabajos from "./CsRevisionReporteTrabajos";
import CsRevisionPresupuesto from "./CsRevisionPresupuesto";
import CsRevisionDetalleFirmas from "./CsRevisionDetalleFirmas";
import CsRevisionTarifasTrabajos from "./CsRevisionTarifasTrabajos";
import CsRevisionTarifasAdicionales from "./CsRevisionTarifasAdicionales";
import CsRevisionTarifasVtoBno from "./CsRevisionTarifasVtoBno";
import CsServicioMapa from "./CsServicioMapa";
import CsRevisionDetalleSegundoConductor from "./CsRevisionDetalleSegundoConductor";

export default {
  name: "CsRevisionDetalles",

  components: {
    CsRevisionReporteTrabajos,
    CsRevisionPresupuesto,
    CsRevisionDetalleFirmas,
    CsRevisionTarifasTrabajos,
    CsRevisionTarifasAdicionales,
    CsRevisionTarifasVtoBno,
    CsRevisionDetalleSegundoConductor,
    CsServicioMapa,
  },

  data() {
    return {
      servicio_id: null,
      servicioInterno: {},
      det_mapa: [],
      conductor_2: false,
      persona_dos: {
        id: null,
        numero_documento: null,
        nombres: null,
        apellidos: null,
      },
    };
  },

  methods: {
    async getIndex(servicio) {
      this.conductor_2 = false;
      this.$parent.cargando = true;
      this.servicio_id = servicio.id;

      this.servicioInterno = {};
      servicio.fecha_ini = moment(servicio.fecha_ini).format(
        "YYYY-MM-DD HH:mm"
      );
      const inicio = moment(servicio.fecha_ini);
      let fecha_ini1 = moment(inicio).format("YYYY-MM-DD");
      let hora_ini1 = moment(inicio).format("HH:mm");

      // Se filtra el contenido de la fecha hora de fin
      let fecha_fin1 = null;
      let hora_fin1 = null;
      if (servicio.fecha_fin) {
        servicio.fecha_fin = moment(servicio.fecha_fin).format(
          "YYYY-MM-DD HH:mm"
        );
        const fin = moment(servicio.fecha_fin);
        fecha_fin1 = moment(fin).format("YYYY-MM-DD");
        hora_fin1 = moment(fin).format("HH:mm");
      }
      let solicitudes_presupuestos = null;
      let clase_solicitud =
        servicio.postulacion.det_solicitud_interna.solicitud_interna
          .clase_solicitud;
      if (servicio.presupuestos.length > 0) {
        solicitudes_presupuestos = servicio.presupuestos;
      }
      let gerente =
        servicio.postulacion.det_solicitud_interna.solicitud_interna
          .gerente_area;
      this.servicioInterno = {
        ...servicio,
        solicitudes_presupuestos,
        clase_solicitud,
        fecha_ini1,
        hora_ini1,
        fecha_fin1,
        hora_fin1,
        gerente,
      };
      this.persona_dos = this.servicioInterno.persona_dos
        ? this.servicioInterno.persona_dos
        : {};
      if (this.persona_dos.id) {
        this.conductor_2 = true;
      }

      await this.$refs.CsRevisionReporteTrabajos.getIndex(this.servicioInterno);
      await this.$refs.CsRevisionTarifasTrabajos.getIndex(this.servicioInterno);
      await this.$refs.CsRevisionTarifasAdicionales.getIndex(
        this.servicioInterno
      );
      await this.$refs.CsRevisionTarifasVtoBno.getIndex(this.servicioInterno);
      await this.$refs.CsRevisionPresupuesto.getIndex();
      if (this.conductor_2) {
        await this.$refs.CsRevisionDetalleSegundoConductor.getIndex();
      }
      this.$parent.cargando = false;
    },

    getDatosMapa() {
      this.det_mapa = [];
      axios
        .get("/api/cs/revision_servicios_internos/ver_mapa", {
          params: {
            servicio_id: this.servicio_id,
          },
        })
        .then((response) => {
          this.det_mapa = response.data;
        });
    },
  },
};
</script>
