var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-lightblue card-outline collapsed-card"},[_c('div',{staticClass:"card-header pt-2 pb-2"},[_c('h3',{staticClass:"card-title text-black text-bold"},[_c('i',{staticClass:"fa fa-truck text-navy"}),_vm._v(" Reporte Trabajo Conductor: "+_vm._s(_vm.$parent.servicioInterno.persona ? _vm.$parent.servicioInterno.persona.nombres + " " + _vm.$parent.servicioInterno.persona.apellidos : "")+" ")]),_c('button',{staticClass:"btn bg-frontera text-white",attrs:{"type":"button","disabled":_vm.estado_detalle == 1 ||
          !_vm.$parent.servicioInterno.fecha_fin1 ||
          !_vm.$parent.servicioInterno.hora_fin1 ||
          _vm.fin_reportes},on:{"click":function($event){return _vm.addDetalle('normal')}}},[_c('i',{staticClass:"fas fa-plus",attrs:{"title":"Añadir"}})]),_vm._m(0)]),(_vm.csDetalles.length > 0 || _vm.estado_detalle == 1)?_c('div',{staticClass:"card-body"},[(_vm.csDetallesExtras.length > 0 || _vm.estado_detalle_extra == 1)?_c('div',{staticClass:"card-title text-info lg"},[_c('i',{staticClass:"fa fa-user-clock"}),_vm._v(" Hora Normal ")]):_vm._e(),_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm"},[_c('thead',{staticClass:"bg-dark"},[_c('tr',{staticClass:"text-center"},[_c('th',{staticClass:"col-3"},[_vm._v("Hora Inicio")]),_c('th',{staticClass:"col-3"},[_vm._v("Hora Fin")]),_vm._m(1),_c('th',{staticClass:"col-2"},[_vm._v("Tipo Tiempo")]),_c('th',{staticClass:"col-2"},[_vm._v("Observaciones")]),(
              _vm.$store.getters.can(
                'cs.revisionServiciosInternos.editReporteTrabajo'
              )
            )?_c('th',{staticClass:"col-1"},[_vm._v(" Acciones ")]):_vm._e()])]),_c('tbody',{attrs:{"id":"tbody"}},[_vm._l((_vm.csDetalles),function(csDetalle,indice){return _c('tr',{key:indice},[_c('td',{staticClass:"text-center"},[(_vm.id_actualizar_detalle == indice && _vm.estado_detalle == 4)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalle.fecha_ini),expression:"servicioDetalle.fecha_ini"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalle.fecha_ini.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"date","disabled":indice < _vm.csDetalles.length - 1},domProps:{"value":(_vm.servicioDetalle.fecha_ini)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalle, "fecha_ini", $event.target.value)},function($event){return _vm.validaHoras(indice, 'normal')}]}})]),_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalle.hora_ini),expression:"servicioDetalle.hora_ini"},{name:"mask",rawName:"v-mask",value:({
                    mask: '99:99',
                    hourFormat: '23',
                  }),expression:"{\n                    mask: '99:99',\n                    hourFormat: '23',\n                  }"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalle.hora_ini.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"text","placeholder":"hora_ini","label":"Hora Inicial","disabled":indice < _vm.csDetalles.length - 1},domProps:{"value":(_vm.servicioDetalle.hora_ini)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalle, "hora_ini", $event.target.value)},function($event){return _vm.validaHoras(indice, 'normal')}]}})])]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(csDetalle.fecha_ini),expression:"csDetalle.fecha_ini"}],staticClass:"form-control form-control-sm",attrs:{"type":"date","disabled":""},domProps:{"value":(csDetalle.fecha_ini)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(csDetalle, "fecha_ini", $event.target.value)}}})]),_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(csDetalle.hora_ini),expression:"csDetalle.hora_ini"},{name:"mask",rawName:"v-mask",value:({
                    mask: '99:99',
                    hourFormat: '23',
                  }),expression:"{\n                    mask: '99:99',\n                    hourFormat: '23',\n                  }"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","placeholder":"hora_ini","label":"Hora Inicial","disabled":""},domProps:{"value":(csDetalle.hora_ini)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(csDetalle, "hora_ini", $event.target.value)}}})])])]),_c('td',{staticClass:"text-center"},[(
                _vm.id_actualizar_detalle == indice &&
                  _vm.estado_detalle == 4 &&
                  indice < _vm.csDetalles.length - 1
              )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalle.fecha_fin),expression:"servicioDetalle.fecha_fin"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalle.fecha_fin.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"date"},domProps:{"value":(_vm.servicioDetalle.fecha_fin)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalle, "fecha_fin", $event.target.value)},function($event){return _vm.validaHoras(indice, 'normal')}]}})]),_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalle.hora_fin),expression:"servicioDetalle.hora_fin"},{name:"mask",rawName:"v-mask",value:({
                    mask: '99:99',
                    hourFormat: '23',
                  }),expression:"{\n                    mask: '99:99',\n                    hourFormat: '23',\n                  }"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalle.hora_fin.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"text","placeholder":"Hora Fin","label":"hora_fin"},domProps:{"value":(_vm.servicioDetalle.hora_fin)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalle, "hora_fin", $event.target.value)},function($event){return _vm.validaHoras(indice, 'normal')}]}})])]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(csDetalle.fecha_fin),expression:"csDetalle.fecha_fin"}],staticClass:"form-control form-control-sm",attrs:{"type":"date","disabled":""},domProps:{"value":(csDetalle.fecha_fin)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(csDetalle, "fecha_fin", $event.target.value)}}})]),_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(csDetalle.hora_fin),expression:"csDetalle.hora_fin"},{name:"mask",rawName:"v-mask",value:({
                    mask: '99:99',
                    hourFormat: '23',
                  }),expression:"{\n                    mask: '99:99',\n                    hourFormat: '23',\n                  }"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","placeholder":"Hora Fin","label":"hora_fin","disabled":""},domProps:{"value":(csDetalle.hora_fin)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(csDetalle, "hora_fin", $event.target.value)}}})])])]),_c('td',{staticClass:"text-center text-nowrap"},[(csDetalle.tiempo)?_c('div',[(csDetalle.tiempo.cant_horas_mostrar)?_c('div',[_vm._v(" "+_vm._s(csDetalle.tiempo.cant_horas_mostrar)+" ")]):_vm._e()]):_c('div',[_vm._v("Sin establecer")])]),_c('td',{staticClass:"text-center"},[(_vm.id_actualizar_detalle == indice && _vm.estado_detalle == 4)?_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalle.tipo_tiempo),expression:"servicioDetalle.tipo_tiempo"}],staticClass:"form-control form-control-sm text-center",class:_vm.$v.servicioDetalle.tipo_tiempo.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"id":"tipo_tiempo"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.servicioDetalle, "tipo_tiempo", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipo_tiempos),function(tipo_tiempo){return _c('option',{key:tipo_tiempo.numeracion,domProps:{"value":tipo_tiempo.numeracion}},[_vm._v(" "+_vm._s(tipo_tiempo.descripcion)+" ")])})],2)]):_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(csDetalle.tipo_tiempo),expression:"csDetalle.tipo_tiempo"}],staticClass:"form-control form-control-sm text-center",attrs:{"id":"tipo_tiempo","disabled":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(csDetalle, "tipo_tiempo", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipo_tiempos),function(tipo_tiempo){return _c('option',{key:tipo_tiempo.numeracion,domProps:{"value":tipo_tiempo.numeracion}},[_vm._v(" "+_vm._s(tipo_tiempo.descripcion)+" ")])})],2)])]),_c('td',{staticClass:"text-center"},[(_vm.id_actualizar_detalle == indice && _vm.estado_detalle == 4)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalle.observaciones),expression:"servicioDetalle.observaciones"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalle.observaciones.$invalid
                    ? 'is-invalid'
                    : 'is-valid',domProps:{"value":(_vm.servicioDetalle.observaciones)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalle, "observaciones", $event.target.value)}}})]):_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(csDetalle.observaciones),expression:"csDetalle.observaciones"}],staticClass:"form-control form-control-sm",attrs:{"disabled":""},domProps:{"value":(csDetalle.observaciones)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(csDetalle, "observaciones", $event.target.value)}}})])]),(
              _vm.$parent.servicioInterno.estado == 1 ||
                (_vm.$parent.servicioInterno.estado == 4 &&
                  _vm.$store.getters.can(
                    'cs.revisionServiciosInternos.editReporteTrabajo'
                  ))
            )?_c('td',{staticClass:"text-center"},[(_vm.estado_detalle != 4)?_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn bg-navy",attrs:{"type":"button","disabled":_vm.estado_detalle != -1},on:{"click":function($event){return _vm.editDetalle(indice, 'normal')}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('button',{staticClass:"btn bg-danger",attrs:{"disabled":_vm.estado_detalle != -1},on:{"click":function($event){return _vm.destroyDetalle(indice, 'normal')}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e(),(_vm.estado_detalle == 4 && _vm.id_actualizar_detalle == indice)?_c('div',{staticClass:"btn-group"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.servicioDetalle.$invalid),expression:"!$v.servicioDetalle.$invalid"}],staticClass:"btn bg-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.save(indice, 'normal')}}},[_c('i',{staticClass:"fas fa-save"})]),_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.cancelDetalle(indice, 'normal')}}},[_c('i',{staticClass:"fa fa-ban"})])]):_vm._e(),(_vm.estado_detalle == 4 && _vm.id_actualizar_detalle !== indice)?_c('div',{staticClass:"btn-group"},[(
                  _vm.$store.getters.can(
                    'cs.revisionServiciosInternos.editReporteTrabajo'
                  )
                )?_c('button',{staticClass:"btn bg-navy",attrs:{"type":"button"},on:{"click":function($event){return _vm.editDetalle(indice, 'normal')}}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e(),(
                  _vm.$store.getters.can(
                    'cs.revisionServiciosInternos.editReporteTrabajo'
                  )
                )?_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.destroyDetalle(indice, 'normal')}}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e()]):_vm._e()]):_vm._e()])}),(_vm.estado_detalle == 1)?_c('tr',[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalle.fecha_ini),expression:"servicioDetalle.fecha_ini"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalle.fecha_ini.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"date","id":"fecha_ini","disabled":""},domProps:{"value":(_vm.servicioDetalle.fecha_ini)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalle, "fecha_ini", $event.target.value)},function($event){return _vm.validaHoras(null, 'normal')}]}})]),_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalle.hora_ini),expression:"servicioDetalle.hora_ini"},{name:"mask",rawName:"v-mask",value:({
                    mask: '99:99',
                    hourFormat: '23',
                  }),expression:"{\n                    mask: '99:99',\n                    hourFormat: '23',\n                  }"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalle.hora_ini.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"text","placeholder":"hora_ini","label":"Hora Inicial","disabled":""},domProps:{"value":(_vm.servicioDetalle.hora_ini)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalle, "hora_ini", $event.target.value)},function($event){return _vm.validaHoras(null, 'normal')}]}})])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalle.fecha_fin),expression:"servicioDetalle.fecha_fin"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalle.fecha_fin.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"date"},domProps:{"value":(_vm.servicioDetalle.fecha_fin)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalle, "fecha_fin", $event.target.value)},function($event){return _vm.validaHoras(null, 'normal')}]}})]),_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalle.hora_fin),expression:"servicioDetalle.hora_fin"},{name:"mask",rawName:"v-mask",value:({
                    mask: '99:99',
                    hourFormat: '23',
                  }),expression:"{\n                    mask: '99:99',\n                    hourFormat: '23',\n                  }"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalle.hora_fin.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"text","placeholder":"Hora Fin","label":"hora_final"},domProps:{"value":(_vm.servicioDetalle.hora_fin)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalle, "hora_fin", $event.target.value)},function($event){return _vm.validaHoras(null, 'normal')}]}})])])]),_vm._m(2),_c('td',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalle.tipo_tiempo),expression:"servicioDetalle.tipo_tiempo"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalle.tipo_tiempo.$invalid
                  ? 'is-invalid'
                  : 'is-valid',attrs:{"id":"tipo_tiempo"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.servicioDetalle, "tipo_tiempo", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipo_tiempos),function(tipo_tiempo){return _c('option',{key:tipo_tiempo.numeracion,domProps:{"value":tipo_tiempo.numeracion}},[_vm._v(" "+_vm._s(tipo_tiempo.descripcion)+" ")])})],2)]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalle.observaciones),expression:"servicioDetalle.observaciones"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalle.observaciones.$invalid
                  ? 'is-invalid'
                  : 'is-valid',domProps:{"value":(_vm.servicioDetalle.observaciones)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalle, "observaciones", $event.target.value)}}})]),_c('td',[_c('div',{staticClass:"btn-group float-right"},[(!_vm.$v.servicioDetalle.$invalid)?_c('button',{staticClass:"btn bg-success",on:{"click":function($event){return _vm.saveDetalle('normal')}}},[_c('i',{staticClass:"fas fa-save"})]):_vm._e(),_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.cancelDetalle(null, 'normal')}}},[_c('i',{staticClass:"fas fa-trash"})])])])]):_vm._e()],2),(_vm.datos_barra)?_c('tbody',[_c('tr',{staticClass:"bg-light"},[_c('td',{attrs:{"colspan":"6"}},[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar progress-bar-striped bg-success progress-bar-animated",style:(`width: ${
                    _vm.datos_barra.operativo.porcentaje != 'NaN'
                      ? _vm.datos_barra.operativo.porcentaje
                      : 0
                  }%`),attrs:{"role":"progressbar","aria-valuenow":"100","aria-valuemin":"0","aria-valuemax":"100"}},[_c('b',[_vm._v(" "+_vm._s(_vm.datos_barra.operativo.tiempo.years ? `Años: ${_vm.datos_barra.operativo.tiempo.years}` : "")+" "+_vm._s(_vm.datos_barra.operativo.tiempo.meses ? `Meses: ${_vm.datos_barra.operativo.tiempo.meses}` : "")+" "+_vm._s(_vm.datos_barra.operativo.tiempo.dias ? `Días: ${_vm.datos_barra.operativo.tiempo.dias}` : "")+" "+_vm._s(_vm.datos_barra.operativo.tiempo.horas ? `Horas: ${_vm.datos_barra.operativo.tiempo.horas}` : "")+" "+_vm._s(_vm.datos_barra.operativo.tiempo.minutos ? `Minutos: ${_vm.datos_barra.operativo.tiempo.minutos}` : "")+" ("+_vm._s(_vm.datos_barra.operativo.porcentaje != "NaN" ? _vm.datos_barra.operativo.porcentaje : 0)+"%) ")])]),_c('div',{staticClass:"progress-bar progress-bar-striped bg-warning progress-bar-animated",style:(`width: ${
                    _vm.datos_barra.disponible.porcentaje != 'NaN'
                      ? _vm.datos_barra.disponible.porcentaje
                      : 0
                  }%`),attrs:{"role":"progressbar","aria-valuenow":"100","aria-valuemin":"0","aria-valuemax":"100"}},[_c('b',[_vm._v(" "+_vm._s(_vm.datos_barra.disponible.tiempo.years ? `Años: ${_vm.datos_barra.disponible.tiempo.years}` : "")+" "+_vm._s(_vm.datos_barra.disponible.tiempo.meses ? `Meses: ${_vm.datos_barra.disponible.tiempo.meses}` : "")+" "+_vm._s(_vm.datos_barra.disponible.tiempo.dias ? `Días: ${_vm.datos_barra.disponible.tiempo.dias}` : "")+" "+_vm._s(_vm.datos_barra.disponible.tiempo.horas ? `Horas: ${_vm.datos_barra.disponible.tiempo.horas}` : "")+" "+_vm._s(_vm.datos_barra.disponible.tiempo.minutos ? `Minutos: ${_vm.datos_barra.disponible.tiempo.minutos}` : "")+" ("+_vm._s(_vm.datos_barra.disponible.porcentaje != "NaN" ? _vm.datos_barra.disponible.porcentaje : 0)+"%) ")])]),_c('div',{staticClass:"progress-bar progress-bar-striped bg-danger progress-bar-animated",style:(`width: ${
                    _vm.datos_barra.indisponible.porcentaje != 'NaN'
                      ? _vm.datos_barra.indisponible.porcentaje
                      : 0
                  }%`),attrs:{"role":"progressbar","aria-valuenow":"100","aria-valuemin":"0","aria-valuemax":"100"}},[_c('b',[_vm._v(" "+_vm._s(_vm.datos_barra.indisponible.tiempo.years ? `Años: ${_vm.datos_barra.indisponible.tiempo.years}` : "")+" "+_vm._s(_vm.datos_barra.indisponible.tiempo.meses ? `Meses: ${_vm.datos_barra.indisponible.tiempo.meses}` : "")+" "+_vm._s(_vm.datos_barra.indisponible.tiempo.dias ? `Días: ${_vm.datos_barra.indisponible.tiempo.dias}` : "")+" "+_vm._s(_vm.datos_barra.indisponible.tiempo.horas ? `Horas: ${_vm.datos_barra.indisponible.tiempo.horas}` : "")+" "+_vm._s(_vm.datos_barra.indisponible.tiempo.minutos ? `Minutos: ${_vm.datos_barra.indisponible.tiempo.minutos}` : "")+" ("+_vm._s(_vm.datos_barra.indisponible.porcentaje != "NaN" ? _vm.datos_barra.indisponible.porcentaje : 0)+"%) ")])]),_c('div',{staticClass:"progress-bar progress-bar-striped bg-primary progress-bar-animated",style:(`width: ${
                    _vm.datos_barra.movilizacion.porcentaje != 'NaN'
                      ? _vm.datos_barra.movilizacion.porcentaje
                      : 0
                  }%`),attrs:{"role":"progressbar","aria-valuenow":"100","aria-valuemin":"0","aria-valuemax":"100"}},[_c('b',[_vm._v(" "+_vm._s(_vm.datos_barra.movilizacion.tiempo.years ? `Años: ${_vm.datos_barra.movilizacion.tiempo.years}` : "")+" "+_vm._s(_vm.datos_barra.movilizacion.tiempo.meses ? `Meses: ${_vm.datos_barra.movilizacion.tiempo.meses}` : "")+" "+_vm._s(_vm.datos_barra.movilizacion.tiempo.dias ? `Días: ${_vm.datos_barra.movilizacion.tiempo.dias}` : "")+" "+_vm._s(_vm.datos_barra.movilizacion.tiempo.horas ? `Horas: ${_vm.datos_barra.movilizacion.tiempo.horas}` : "")+" "+_vm._s(_vm.datos_barra.movilizacion.tiempo.minutos ? `Minutos: ${_vm.datos_barra.movilizacion.tiempo.minutos}` : "")+" ("+_vm._s(_vm.datos_barra.movilizacion.porcentaje != "NaN" ? _vm.datos_barra.movilizacion.porcentaje : 0)+"%) ")])])])])])]):_vm._e()]),_c('br'),(_vm.csDetallesExtras.length > 0 || _vm.estado_detalle_extra == 1)?_c('div',{staticClass:"card-title text-info lg"},[_c('i',{staticClass:"fa fa-user-clock"}),_vm._v(" Hora Extra ")]):_vm._e(),(_vm.csDetallesExtras.length > 0 || _vm.estado_detalle_extra == 1)?_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm p-2"},[_c('thead',{staticClass:"bg-dark"},[_c('tr',{staticClass:"text-center"},[_c('th',{staticClass:"col-3"},[_vm._v("Hora Inicio")]),_c('th',{staticClass:"col-3"},[_vm._v("Hora Fin")]),_vm._m(3),_c('th',{staticClass:"col-2"},[_vm._v("Tipo Tiempo")]),_c('th',{staticClass:"col-2"},[_vm._v("Observaciones")]),(
              _vm.$store.getters.can(
                'cs.revisionServiciosInternos.editReporteTrabajo'
              )
            )?_c('th',{staticClass:"col-1"},[_vm._v(" Acciones ")]):_vm._e()])]),_c('tbody',{attrs:{"id":"tbody"}},[_vm._l((_vm.csDetallesExtras),function(csDetalleExtra,index){return _c('tr',{key:csDetalleExtra.id},[_c('td',{staticClass:"text-center"},[(
                _vm.id_actualizar_detalle_extra == index &&
                  _vm.estado_detalle_extra == 2
              )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalleExtra.fecha_ini),expression:"servicioDetalleExtra.fecha_ini"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalleExtra.fecha_ini.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"date","disabled":""},domProps:{"value":(_vm.servicioDetalleExtra.fecha_ini)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalleExtra, "fecha_ini", $event.target.value)},function($event){return _vm.validaHoras(index, 'extra')}]}})]),_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalleExtra.hora_ini),expression:"servicioDetalleExtra.hora_ini"},{name:"mask",rawName:"v-mask",value:({
                    mask: '99:99',
                    hourFormat: '23',
                  }),expression:"{\n                    mask: '99:99',\n                    hourFormat: '23',\n                  }"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalleExtra.hora_ini.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"text","placeholder":"hora_ini","label":"Hora Inicial","disabled":""},domProps:{"value":(_vm.servicioDetalleExtra.hora_ini)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalleExtra, "hora_ini", $event.target.value)},function($event){return _vm.validaHoras(index, 'extra')}]}})])]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(csDetalleExtra.fecha_ini),expression:"csDetalleExtra.fecha_ini"}],staticClass:"form-control form-control-sm",attrs:{"type":"date","disabled":""},domProps:{"value":(csDetalleExtra.fecha_ini)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(csDetalleExtra, "fecha_ini", $event.target.value)}}})]),_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(csDetalleExtra.hora_ini),expression:"csDetalleExtra.hora_ini"},{name:"mask",rawName:"v-mask",value:({
                    mask: '99:99',
                    hourFormat: '23',
                  }),expression:"{\n                    mask: '99:99',\n                    hourFormat: '23',\n                  }"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","placeholder":"hora_ini","label":"Hora Inicial","disabled":""},domProps:{"value":(csDetalleExtra.hora_ini)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(csDetalleExtra, "hora_ini", $event.target.value)}}})])])]),_c('td',{staticClass:"text-center"},[(
                _vm.id_actualizar_detalle_extra == index &&
                  _vm.estado_detalle_extra == 2
              )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalleExtra.fecha_fin),expression:"servicioDetalleExtra.fecha_fin"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalleExtra.fecha_fin.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"date"},domProps:{"value":(_vm.servicioDetalleExtra.fecha_fin)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalleExtra, "fecha_fin", $event.target.value)},function($event){return _vm.validaHoras(index, 'extra')}]}})]),_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalleExtra.hora_fin),expression:"servicioDetalleExtra.hora_fin"},{name:"mask",rawName:"v-mask",value:({
                    mask: '99:99',
                    hourFormat: '23',
                  }),expression:"{\n                    mask: '99:99',\n                    hourFormat: '23',\n                  }"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalleExtra.hora_fin.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"text","placeholder":"Hora Fin","label":"hora_fin"},domProps:{"value":(_vm.servicioDetalleExtra.hora_fin)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalleExtra, "hora_fin", $event.target.value)},function($event){return _vm.validaHoras(index, 'extra')}]}})])]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(csDetalleExtra.fecha_fin),expression:"csDetalleExtra.fecha_fin"}],staticClass:"form-control form-control-sm",attrs:{"type":"date","disabled":""},domProps:{"value":(csDetalleExtra.fecha_fin)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(csDetalleExtra, "fecha_fin", $event.target.value)}}})]),_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(csDetalleExtra.hora_fin),expression:"csDetalleExtra.hora_fin"},{name:"mask",rawName:"v-mask",value:({
                    mask: '99:99',
                    hourFormat: '23',
                  }),expression:"{\n                    mask: '99:99',\n                    hourFormat: '23',\n                  }"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","placeholder":"Hora Fin","label":"hora_fin","disabled":""},domProps:{"value":(csDetalleExtra.hora_fin)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(csDetalleExtra, "hora_fin", $event.target.value)}}})])])]),_c('td',{staticClass:"text-center text-nowrap"},[(csDetalleExtra.tiempo)?_c('div',[(csDetalleExtra.tiempo.cant_horas_mostrar)?_c('div',[_vm._v(" "+_vm._s(csDetalleExtra.tiempo.cant_horas_mostrar)+" ")]):_vm._e()]):_c('div',[_vm._v("Sin establecer")])]),_c('td',{staticClass:"text-center"},[(
                _vm.id_actualizar_detalle_extra == index &&
                  _vm.estado_detalle_extra == 2
              )?_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalleExtra.tipo_tiempo),expression:"servicioDetalleExtra.tipo_tiempo"}],staticClass:"form-control form-control-sm text-center",class:_vm.$v.servicioDetalleExtra.tipo_tiempo.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"id":"tipo_tiempo"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.servicioDetalleExtra, "tipo_tiempo", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.tipo_tiempos_extra),function(tipo_tiempo){return _c('option',{key:tipo_tiempo.numeracion,domProps:{"value":tipo_tiempo.numeracion}},[_vm._v(" "+_vm._s(tipo_tiempo.descripcion)+" ")])})],2)]):_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(csDetalleExtra.tipo_tiempo),expression:"csDetalleExtra.tipo_tiempo"}],staticClass:"form-control form-control-sm text-center",attrs:{"id":"tipo_tiempo","disabled":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(csDetalleExtra, "tipo_tiempo", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.tipo_tiempos_extra),function(tipo_tiempo){return _c('option',{key:tipo_tiempo.numeracion,domProps:{"value":tipo_tiempo.numeracion}},[_vm._v(" "+_vm._s(tipo_tiempo.descripcion)+" ")])})],2)])]),_c('td',{staticClass:"text-center"},[(
                _vm.id_actualizar_detalle_extra == index &&
                  _vm.estado_detalle_extra == 2
              )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalleExtra.observaciones),expression:"servicioDetalleExtra.observaciones"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalleExtra.observaciones.$invalid
                    ? 'is-invalid'
                    : 'is-valid',domProps:{"value":(_vm.servicioDetalleExtra.observaciones)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalleExtra, "observaciones", $event.target.value)}}})]):_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(csDetalleExtra.observaciones),expression:"csDetalleExtra.observaciones"}],staticClass:"form-control form-control-sm",attrs:{"disabled":""},domProps:{"value":(csDetalleExtra.observaciones)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(csDetalleExtra, "observaciones", $event.target.value)}}})])]),(
              _vm.$store.getters.can(
                'cs.revisionServiciosInternos.editReporteTrabajo'
              )
            )?_c('td',{staticClass:"text-center"},[(_vm.estado_detalle_extra != 2)?_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn bg-navy",attrs:{"type":"button","disabled":_vm.estado_detalle_extra != -1},on:{"click":function($event){return _vm.editDetalle(index, 'extra')}}},[_c('i',{staticClass:"fas fa-edit"})])]):_vm._e(),(
                _vm.estado_detalle_extra == 2 &&
                  _vm.id_actualizar_detalle_extra == index
              )?_c('div',{staticClass:"btn-group"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.servicioDetalleExtra.$invalid),expression:"!$v.servicioDetalleExtra.$invalid"}],staticClass:"btn bg-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.save(index, 'extra')}}},[_c('i',{staticClass:"fas fa-save"})]),_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.cancelDetalle(index, 'extra')}}},[_c('i',{staticClass:"fa fa-ban"})])]):_vm._e(),(
                _vm.estado_detalle_extra == 2 &&
                  _vm.id_actualizar_detalle_extra !== index
              )?_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn bg-navy",attrs:{"type":"button"},on:{"click":function($event){return _vm.editDetalle(index, 'extra')}}},[_c('i',{staticClass:"fas fa-edit"})])]):_vm._e()]):_vm._e()])}),(_vm.estado_detalle_extra == 1)?_c('tr',[_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalleExtra.fecha_ini),expression:"servicioDetalleExtra.fecha_ini"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalleExtra.fecha_ini.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"date","id":"fecha_ini","disabled":""},domProps:{"value":(_vm.servicioDetalleExtra.fecha_ini)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalleExtra, "fecha_ini", $event.target.value)},function($event){return _vm.validaHoras(_vm.index, 'extra')}]}})]),_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalleExtra.hora_ini),expression:"servicioDetalleExtra.hora_ini"},{name:"mask",rawName:"v-mask",value:({
                    mask: '99:99',
                    hourFormat: '23',
                  }),expression:"{\n                    mask: '99:99',\n                    hourFormat: '23',\n                  }"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalleExtra.hora_ini.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"text","placeholder":"hora_ini","label":"Hora Inicial","disabled":""},domProps:{"value":(_vm.servicioDetalleExtra.hora_ini)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalleExtra, "hora_ini", $event.target.value)},function($event){return _vm.validaHoras(_vm.index, 'extra')}]}})])])]),_c('td',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalleExtra.fecha_fin),expression:"servicioDetalleExtra.fecha_fin"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalleExtra.fecha_fin.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"date"},domProps:{"value":(_vm.servicioDetalleExtra.fecha_fin)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalleExtra, "fecha_fin", $event.target.value)},function($event){return _vm.validaHoras(_vm.index, 'extra')}]}})]),_c('div',{staticClass:"col-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalleExtra.hora_fin),expression:"servicioDetalleExtra.hora_fin"},{name:"mask",rawName:"v-mask",value:({
                    mask: '99:99',
                    hourFormat: '23',
                  }),expression:"{\n                    mask: '99:99',\n                    hourFormat: '23',\n                  }"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalleExtra.hora_fin.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"text","placeholder":"Hora Fin","label":"hora_final"},domProps:{"value":(_vm.servicioDetalleExtra.hora_fin)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalleExtra, "hora_fin", $event.target.value)},function($event){return _vm.validaHoras(_vm.index, 'extra')}]}})])])]),_vm._m(4),_c('td',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalleExtra.tipo_tiempo),expression:"servicioDetalleExtra.tipo_tiempo"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalleExtra.tipo_tiempo.$invalid
                  ? 'is-invalid'
                  : 'is-valid',attrs:{"id":"tipo_tiempo"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.servicioDetalleExtra, "tipo_tiempo", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.tipo_tiempos_extra),function(tipo_tiempo){return _c('option',{key:tipo_tiempo.numeracion,domProps:{"value":tipo_tiempo.numeracion}},[_vm._v(" "+_vm._s(tipo_tiempo.descripcion)+" ")])})],2)]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.servicioDetalleExtra.observaciones),expression:"servicioDetalleExtra.observaciones"}],staticClass:"form-control form-control-sm",class:_vm.$v.servicioDetalleExtra.observaciones.$invalid
                  ? 'is-invalid'
                  : 'is-valid',domProps:{"value":(_vm.servicioDetalleExtra.observaciones)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.servicioDetalleExtra, "observaciones", $event.target.value)}}})]),_c('td',[_c('div',{staticClass:"btn-group float-right"},[(!_vm.$v.servicioDetalleExtra.$invalid)?_c('button',{staticClass:"btn bg-success",on:{"click":function($event){return _vm.saveDetalle('extra')}}},[_c('i',{staticClass:"fas fa-save"})]):_vm._e(),_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.cancelDetalle(null, 'extra')}}},[_c('i',{staticClass:"fas fa-trash"})])])])]):_vm._e()],2)]):_vm._e()]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","data-card-widget":"collapse","data-toggle":"tooltip","title":"Collapse"}},[_c('i',{staticClass:"fas fa-plus"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"col-1"},[_vm._v("Duración "),_c('small',[_vm._v("(horas)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center text-muted text-nowrap"},[_c('small',[_vm._v("Por establecer")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"col-1"},[_vm._v("Duración "),_c('small',[_vm._v("(horas)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center text-muted text-nowrap"},[_c('small',[_vm._v("Por establecer")])])
}]

export { render, staticRenderFns }