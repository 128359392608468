<template>
  <div class="card card-lightblue card-outline collapsed-card">
    <div class="card-header pt-2 pb-2">
      <!-- <h3 class="card-title">
        <i class="fa fa-file-signature text-dark"></i> Cargos Contables
      </h3> -->
      <div class="row justify-content-between">
        <h3 class="card-title text-black text-bold">
          <i class="fa fa-file-signature text-navy"></i> Asignación Cargos
          Contables
          <small v-if="$parent.servicioInterno.clase_solicitud == 1"
            >(Fijos)</small
          >
          <small v-if="$parent.servicioInterno.clase_solicitud == 2"
            >(LLamados)</small
          >
        </h3>
        <div
          class="form-group col-md-7"
          v-if="
            listasForms.asignaciones_contables.length > 0 &&
              $parent.servicioInterno.clase_solicitud == 1
          "
        >
          <v-select
            class="col-md-8"
            style="min-width: 30em;"
            v-model="asignacionContable"
            placeholder="Asignación Contable"
            label="nombre"
            :options="listasForms.asignaciones_contables"
            @input="cambiarAsignaciones"
            :disabled="estado_contable == 1 || totalPresupuesto == 100"
          ></v-select>
        </div>
        <div
          class="form-group col-md-7"
          v-if="$parent.servicioInterno.clase_solicitud == 2"
        >
          <label>Gerente Area</label>
          <div class="row">
            <div class="col-md-8">
              <input
                v-model="gerente"
                type="text"
                disabled
                class="form-control form-control-sm text-center"
              />
            </div>
          </div>
        </div>

        <div class="card-tools">
          <button
            v-if="
              asignacionContable && $parent.servicioInterno.clase_solicitud == 1
            "
            type="button"
            class="btn bg-danger text-white"
            @click="destroyContable('completo')"
          >
            <i class="fas fa-trash"></i>
          </button>
          <button
            v-if="$parent.servicioInterno.clase_solicitud == 2"
            type="button"
            class="btn bg-frontera text-white"
            @click="addContable()"
            :disabled="estado_contable == 1 || totalPresupuesto == 100"
          >
            <i class="fas fa-plus"></i>
          </button>
          <button
            type="button"
            data-card-widget="collapse"
            data-toggle="tooltip"
            title="Collapse"
            class="btn btn-tool"
          >
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
    <div
      class="col-md-12 mb-0 text-center"
      v-if="csContables.length > 0"
      :class="
        totalPresupuesto == 100
          ? 'text-success'
          : totalPresupuesto < 100
          ? 'text-warning'
          : 'text-danger'
      "
    >
      <h4>Porcentaje Total: {{ totalPresupuesto }}%</h4>
    </div>
    <div
      class="card-body table-responsive"
      v-if="csContables.length > 0 || estado_contable == 1"
    >
      <table class="table table-bordered table-striped table-hover table-sm">
        <thead class="text-center bg-dark">
          <tr>
            <th class="text-center">Area</th>
            <th class="text-center text-nowrap">Dueño AFE</th>
            <th class="text-center">Tipo Presupuesto</th>
            <th class="text-center">CECO/AFE/G&A</th>
            <th class="text-center">Activo Producción</th>
            <th class="text-center" style="min-width: 8em">
              % de la operación para cargar este CECO/AFE
            </th>
            <th
              style="width: 10em;"
              v-if="$parent.servicioInterno.clase_solicitud == 2"
            >
              Acciones
            </th>
          </tr>
        </thead>
        <tbody id="tbody">
          <tr v-for="(csContable, index) in csContables" :key="csContable.id">
            <td>
              <div
                v-if="id_actualizar_contable == index && estado_contable == 2"
              >
                <v-select
                  class="form-control form-control-sm mr-3 p-0"
                  :class="$v.contable.area.$invalid ? 'is-invalid' : 'is-valid'"
                  v-model="area"
                  placeholder="Areas"
                  label="descripcion"
                  :options="listasForms.areas"
                  @input="getSelectArea()"
                ></v-select>
              </div>
              <div v-else>
                {{ csContable.nArea }}
              </div>
            </td>
            <td class="text-center">
              <div
                v-if="id_actualizar_contable == index && estado_contable == 2"
              >
                <v-select
                  class="form-control form-control-sm p-0 mr-3 p-0"
                  :class="[
                    $v.contable.responsable_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="responsable"
                  placeholder="Nombre Responsable"
                  label="funcionario"
                  :options="listasForms.responsables"
                  @input="getSelectResposable"
                ></v-select>
              </div>
              <div v-else>
                <div v-if="csContable.responsable.nombres">
                  {{
                    csContable.responsable.nombres
                      ? csContable.responsable.nombres +
                        " " +
                        csContable.responsable.apellidos
                      : ""
                  }}
                </div>

                <div v-else>{{ csContable.responsable.funcionario }}</div>
              </div>
            </td>
            <td class="text-center">
              <v-select
                class="form-control form-control-sm mr-3 p-0"
                style="z-index: 1000;"
                v-if="id_actualizar_contable == index && estado_contable == 2"
                :class="[
                  $v.contable.tipo_presupuesto.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                ]"
                v-model="contable.tipoPresupuesto"
                placeholder="Tipo Presupuesto"
                label="descripcion"
                :options="listasForms.tipo_presupuesto"
                @input="[resetCecoAfe(), getCecosAfes()]"
              ></v-select>
              <span
                v-else
                class="badge"
                :class="
                  csContable.tipo_presupuesto == 1
                    ? 'bg-info'
                    : csContable.tipo_presupuesto == 2
                    ? 'bg-navy'
                    : 'bg-lime'
                "
              >
                {{
                  csContable.tipo_presupuesto
                    ? csContable.nTipoPresupuesto
                    : null
                }}
              </span>
            </td>
            <td class="text-center">
              <v-select
                v-model="ceco_afe"
                v-if="id_actualizar_contable == index && estado_contable == 2"
                placeholder="Ceco Afe"
                label="codigo_contable"
                :options="listasForms.ceco_afes"
                class="form-control form-control-sm mr-3 p-0"
                :class="
                  $v.contable.ceco_afe_id.$invalid ? 'is-invalid' : 'is-valid'
                "
                @input="getSelectCeco()"
                :disabled="contable.tipo_presupuesto == null"
              ></v-select>
              <v-select
                v-model="csContable.ceco_afe"
                v-else
                placeholder="Ceco Afe"
                label="codigo_contable"
                :options="listasForms.ceco_afes"
                class="form-control form-control-sm mr-3 p-0"
                disabled
              ></v-select>
            </td>
            <!-- <td class="text-center">
              <div class="row">
                <div class="col-md-4">
                  <span
                    class="badge"
                    v-if="csContable.nTipoPresupuesto"
                    :class="
                      csContable.tipo_presupuesto == 1
                        ? 'bg-info'
                        : csContable.tipo_presupuesto == 2
                        ? 'bg-navy'
                        : csContable.tipo_presupuesto == 2
                        ? 'bg-lime'
                        : 'bg-secundary'
                    "
                  >
                    {{ csContable.nTipoPresupuesto }}
                  </span>
                </div>
                <div class="col-md-8">
                  {{
                    csContable.ceco_afe
                      ? csContable.ceco_afe.codigo_contable
                      : ""
                  }}
                </div>
              </div>
            </td> 
            <td>{{ csContable.nActivoProduccion }}</td>-->
            <td class="text-center">
              <div
                v-if="id_actualizar_contable == index && estado_contable == 2"
              >
                <v-select
                  class="form-control form-control-sm mr-3 p-0"
                  :class="[
                    $v.contable.activo_produccion.$invalid
                      ? 'is-invalid'
                      : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="activo_produccion"
                  placeholder="Tipo Presupuesto"
                  label="descripcion"
                  :options="listasForms.activosProduccion"
                  @input="getSelectActivosProduccion"
                ></v-select>
              </div>
              <div v-else>
                {{ csContable.nActivoProduccion }}
              </div>
            </td>
            <td class="text-center">
              <div
                v-if="id_actualizar_contable == index && estado_contable == 2"
              >
                <input
                  type="number"
                  class="form-control form-control-sm"
                  v-model="contable.porcentaje"
                  :class="
                    $v.contable.porcentaje.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @input="limitarTresDigitos()"
                />
              </div>
              <div v-else>{{ csContable.porcentaje }}</div>
            </td>
            <td
              class="text-center"
              v-if="$parent.servicioInterno.clase_solicitud == 2"
            >
              <div class="text-center" v-if="estado_contable != 2">
                <button
                  type="button"
                  class="btn bg-navy"
                  :disabled="estado_contable != -1"
                  @click="editContable(index)"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  class="btn bg-danger"
                  :disabled="estado_contable != -1"
                  @click="destroyContable(index)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
              <div
                class="btn-group float-right"
                v-if="estado_contable == 2 && id_actualizar_contable == index"
              >
                <button
                  type="button"
                  class="btn bg-success"
                  v-show="!$v.contable.$invalid"
                  @click="saveUpdateContable(index)"
                >
                  <i class="fas fa-save"></i>
                </button>
                <button class="btn bg-danger" @click="cancelContable()">
                  <i class="fas fa-ban"></i>
                </button>
              </div>
              <div
                class="btn-group float-right"
                v-if="
                  estado_contable == 2 &&
                    id_actualizar_contable !== index &&
                    $parent.servicioInterno.clase_solicitud == 2
                "
              >
                <button
                  type="button"
                  class="btn bg-navy"
                  disabled
                  @click="editContable(index)"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  class="btn bg-danger"
                  disabled
                  @click="destroyContable(index)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="estado_contable == 1">
            <td class="text-center">
              <v-select
                class="form-control form-control-sm mr-3 p-0"
                :class="$v.contable.area.$invalid ? 'is-invalid' : 'is-valid'"
                v-model="area"
                placeholder="Areas"
                label="descripcion"
                :options="listasForms.areas"
                @input="getSelectArea()"
              ></v-select>
            </td>
            <td class="text-center">
              <v-select
                class="form-control form-control-sm p-0 mr-3 p-0"
                :class="[
                  $v.contable.responsable_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                ]"
                v-model="responsable"
                placeholder="Nombre Responsable"
                label="funcionario"
                :options="listasForms.responsables"
                @input="getSelectResposable"
              ></v-select>
            </td>
            <td class="text-center">
              <v-select
                class="form-control form-control-sm mr-3 p-0"
                :class="[
                  $v.contable.tipo_presupuesto.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                ]"
                v-model="contable.tipoPresupuesto"
                placeholder="Tipo Presupuesto"
                label="descripcion"
                :options="listasForms.tipo_presupuesto"
                @input="[resetCecoAfe(), getCecosAfes()]"
              ></v-select>
            </td>
            <td class="text-center">
              <v-select
                v-model="ceco_afe"
                placeholder="Ceco Afe"
                label="codigo_contable"
                :options="listasForms.ceco_afes"
                class="form-control form-control-sm mr-3 p-0"
                :class="
                  $v.contable.ceco_afe_id.$invalid ? 'is-invalid' : 'is-valid'
                "
                @input="getSelectCeco()"
                :disabled="contable.tipo_presupuesto == null"
              ></v-select>
            </td>
            <td class="text-center">
              <v-select
                class="form-control form-control-sm p-0 mr-3 p-0"
                :class="[
                  $v.contable.activo_produccion.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                ]"
                v-model="activo_produccion"
                placeholder="Tipo Presupuesto"
                label="descripcion"
                :options="listasForms.activosProduccion"
                @input="getSelectActivosProduccion"
              ></v-select>
            </td>
            <td class="text-center">
              <input
                type="number"
                class="form-control form-control-sm p-0 mr-3 p-0"
                id="porcentaje"
                v-model="contable.porcentaje"
                :class="
                  $v.contable.porcentaje.$invalid ? 'is-invalid' : 'is-valid'
                "
                @input="limitarTresDigitos()"
              />
            </td>
            <td class="text-center">
              <div class="btn-group float-right">
                <button
                  class="btn bg-success"
                  v-show="!$v.contable.$invalid"
                  @click="saveContable()"
                >
                  <i class="fas fa-save"></i>
                </button>
                <button class="btn bg-danger" @click="cancelContable()">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "CsRevisionPresupuesto",
  components: {
    vSelect,
  },
  data() {
    return {
      area: {},
      totalPresupuesto: 0,
      responsable: {},
      activo_produccion: {},
      ceco_afe: {},
      contable: {},
      gerente: "",
      asignacionContable: null,
      listasForms: {
        ceco_afes: [],
        asignaciones_contables: [],
        areas: [],
        activosProduccion: [],
        responsables: [],
        tipo_presupuesto: [],
      },
      csContables: [],
      estado_contable: -1,
      id_actualizar_contable: -1,
    };
  },
  validations() {
    if (this.$parent.servicioInterno.clase_solicitud == 1) {
      return {
        contable: {
          tipo_presupuesto: {
            required,
          },
          ceco_afe_id: {
            required,
          },
          codigo_ceco_afe: {
            required,
          },
          porcentaje: {
            required,
          },
          area: {
            required,
          },
          activo_produccion: {
            required,
          },
          responsable_id: {
            required,
          },
        },
      };
    }
    if (this.$parent.servicioInterno.clase_solicitud == 2) {
      return {
        contable: {
          tipo_presupuesto: {
            required,
          },
          ceco_afe_id: {
            required,
          },
          codigo_ceco_afe: {
            required,
          },
          porcentaje: {
            required,
          },
          area: {
            required,
          },
          activo_produccion: {
            required,
          },
          responsable_id: {
            required,
          },
        },
      };
    }
  },
  methods: {
    async getIndex() {
      this.estado_contable = -1;
      this.id_actualizar_contable = -1;
      this.csContables = [];
      await this.getAsignacionesContables();
      await this.getActivosProduccion();
      await this.getAreas();
      await this.getResponsables();
      await this.getTipoPresupuesto();
      this.gerente = this.$parent.servicioInterno.gerente
        ? this.$parent.servicioInterno.gerente.nombres +
          " " +
          this.$parent.servicioInterno.gerente.apellidos
        : "";
      if (
        this.$parent.servicioInterno.solicitudes_presupuestos &&
        this.$parent.servicioInterno.solicitudes_presupuestos.length > 0
      ) {
        this.$parent.servicioInterno.solicitudes_presupuestos.forEach(
          (presupuesto) => {
            // Se valida el nombre del funcionario
            if (presupuesto.responsable) {
              presupuesto.responsable.nombre = presupuesto.responsable
                ? `${presupuesto.responsable.nombres} ${presupuesto.responsable.apellidos}`
                : null;
            }
            if (
              //!this.asignacionContable &&
              this.$parent.servicioInterno.clase_solicitud == 1
            ) {
              this.asignacionContable = this.listasForms.asignaciones_contables.find(
                (item) => item.id === presupuesto.cs_asignacion_contable.id
              );
            }
            this.csContables.push({
              id: presupuesto.id,
              tipo_presupuesto: presupuesto.ceco_afe.tipo_presupuesto,
              nTipoPresupuesto: presupuesto.nTipoPresupuesto,
              cs_asignacion_contable_id: presupuesto.cs_asignacion_contable
                ? presupuesto.cs_asignacion_contable.id
                : null,
              imputacion: presupuesto.cs_asignacion_contable
                ? presupuesto.cs_asignacion_contable.nombre
                : "",
              area: presupuesto.area ? presupuesto.area : null,
              nArea: presupuesto.nArea ? presupuesto.nArea : "",
              nActivoProduccion: presupuesto.nActivoProduccion
                ? presupuesto.nActivoProduccion
                : "",
              responsable: presupuesto.responsable
                ? presupuesto.responsable
                : null,
              nResponsable: presupuesto.responsable
                ? presupuesto.responsable.nombre
                : "",
              responsable_id: presupuesto.responsable
                ? presupuesto.responsable.id
                : "",
              activo_produccion: presupuesto.activo_produccion
                ? presupuesto.activo_produccion
                : null,
              ceco_afe_id: presupuesto.ceco_afe.id,
              codigo_ceco_afe: presupuesto.ceco_afe.codigo_contable,
              porcentaje: parseFloat(presupuesto.porcentaje),
              cs_servicio_interno_id: this.$parent.servicioInterno.id,
              tipoPresupuesto: this.listasForms.tipo_presupuesto.find(
                (e) => e.numeracion == presupuesto.tipo_presupuesto
              ),
              ceco_afe: presupuesto.ceco_afe,
            });
          }
        );
      }

      await this.calcularContable();
    },

    async editContable(index) {
      this.limpiarContable();
      this.ceco_afe = this.csContables[index].ceco_afe;
      this.ceco_afe;
      this.id_actualizar_contable = index;
      this.estado_contable = 2;
      this.contable = { ...this.csContables[index] };

      this.contable.tipoPresupuesto = this.listasForms.tipo_presupuesto.find(
        (item) => item.numeracion === this.contable.ceco_afe.tipo_presupuesto
      );

      this.area = this.listasForms.areas.find(
        (item) => item.numeracion === this.contable.area
      );

      this.activo_produccion = this.listasForms.activosProduccion.find(
        (item) => item.numeracion === this.contable.activo_produccion
      );

      this.responsable = this.listasForms.responsables.find(
        (item) => item.id === this.contable.responsable_id
      );

      this.ceco_afe_obj = { ...this.csContables[index].ceco_afe_obj };
      await this.getCecosAfes();
    },

    async getCecosAfes() {
      this.contable.tipo_presupuesto = null;
      if (this.contable.tipoPresupuesto) {
        this.contable.tipo_presupuesto = this.contable.tipoPresupuesto.numeracion;
        this.contable.nTipoPresupuesto = this.contable.tipoPresupuesto.descripcion;
        await axios
          .get("/api/funcionariosFrontera/wsObjetosActivos/lista", {
            params: {
              tipo_presupuesto: this.contable.tipo_presupuesto,
              linea_negocio: 4,
            },
          })
          .then(async (response) => {
            this.listasForms.ceco_afes = response.data;
            if (this.listasForms.ceco_afes.length == 0) {
              await this.$swal({
                icon: "warning",
                title: "Advertencia!!",
                text:
                  "No se encontró datos para el Tipo Presupuesto. Por favor validar.!",
                timer: 4000,
                backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
                timerProgressBar: true,
                confirmButtonText: "Aceptar",
              });
            }
          });
      }
    },

    resetCecoAfe() {
      this.contable.ceco_afe = null;
      this.contable.ceco_afe_id = null;
      this.ceco_afe = null;
    },

    async limpiarContable() {
      this.contable = {
        tipo_presupuesto: null,
        ceco_afe_id: null,
        codigo_ceco_afe: null,
        porcentaje: null,
        cs_servicio_interno_id: null,
        tipoPresupuesto: null,
        ceco_afe: null,
        area: null,
        activo_produccion: null,
        responsable_id: null,
        gerente_id: null,
      };
      this.responsable = {};
      this.activo_produccion = {};
      this.area = {};
      this.ceco_afe = {};
    },

    async getTipoPresupuesto() {
      this.listasForms.tipo_presupuesto = [];
      await axios.get("/api/lista/151").then((response) => {
        this.listasForms.tipo_presupuesto = response.data;
      });
    },

    async getSelectActivosProduccion() {
      this.contable.activo_produccion = null;
      this.contable.nActivoProduccion = null;
      if (this.activo_produccion) {
        //this.contable.activo_produccion = { ...this.activo_produccion };
        this.contable.activo_produccion = this.activo_produccion.numeracion;
        this.contable.nActivoProduccion = this.activo_produccion.descripcion;
      }
    },

    async getAreas() {
      await axios.get("/api/lista/99").then((response) => {
        this.listasForms.areas = response.data;
      });
    },

    async getActivosProduccion() {
      await axios.get("/api/lista/137").then((response) => {
        this.listasForms.activosProduccion = response.data;
      });
    },

    getSelectCeco() {
      this.contable.ceco_afe_id = null;
      if (this.ceco_afe) {
        this.contable.ceco_afe = { ...this.ceco_afe };
        this.contable.ceco_afe_id = this.ceco_afe.id;
        this.contable.codigo_ceco_afe = this.ceco_afe.codigo_contable;
      }
    },

    getSelectArea() {
      this.contable.area = null;
      this.contable.nArea = null;
      if (this.area) {
        //this.contable.area = { ...this.area };
        this.contable.area = this.area.numeracion;
        this.contable.nArea = this.area.descripcion;
      }
    },

    getSelectResposable() {
      this.contable.responsable_id = null;
      if (this.responsable) {
        this.contable.responsable_id = this.responsable.id;
        this.contable.responsable = this.responsable;
      }
    },

    async cancelContable() {
      this.limpiarContable();
      this.estado_contable = -1;
    },

    limitarTresDigitos() {
      if (this.contable.porcentaje !== null) {
        if (this.contable.porcentaje > 100) {
          // Establece el valor máximo en 100
          this.contable.porcentaje = 100;
        } else if (this.contable.porcentaje < 0) {
          this.contable.porcentaje = null;
        }
      }
    },

    async getResponsables() {
      await axios.get("/api/admin/funcionarios/list").then((response) => {
        this.listasForms.responsables = response.data;
      });
    },

    async getAsignacionesContables() {
      this.listasForms.asignaciones_contables = [];
      await axios
        .get("/api/cs/asignacionesContables/lista", {
          params: {
            bloque_id: this.$parent.servicioInterno.bloque_id,
            estado: 1,
          },
        })
        .then((response) => {
          this.listasForms.asignaciones_contables = response.data;
        });
    },

    async destroyContable(index) {
      if (index == "completo") {
        this.$swal({
          title: "Quitar Cargos Contables.",
          text: "Está seguro de quitar los cargos contables?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Quitar!",
        }).then(async (result) => {
          this.asignacionContable = null;
          this.csContables = [];
          await this.calcularContable();
          await this.saveContableTotal();
        });
      } else {
        this.$swal({
          title: "Quitar Cargo Contable.",
          text: "Está seguro de quitar este cargo contable?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Quitar!",
        }).then(async (result) => {
          if (result.value) {
            this.csContables.splice(index, 1);
            await this.calcularContable();
            await this.saveContableTotal();
          }
        });
      }
    },

    async addContable() {
      await this.limpiarContable();
      this.contable.cs_servicio_interno_id = this.$parent.servicioInterno.id;
      this.estado_contable = 1;
    },

    async saveContable() {
      let me = this;
      await me.calcularContable();
      if (
        me.totalPresupuesto + parseFloat(me.contable.porcentaje) <= 100 &&
        me.contable.porcentaje > 0
      ) {
        me.csContables.push(me.contable);
        me.estado_contable = -1;
        await me.calcularContable();
        await me.saveContableTotal();
      } else {
        me.$swal({
          icon: "error",
          title: "Ocurrió un error... El porcentaje no puede ser mayor al 100%",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    async saveUpdateContable(index) {
      let me = this;
      let sumaPorcentaje = 0;
      if (index != null) {
        this.csContables.forEach((element, key) => {
          if (key !== index) {
            sumaPorcentaje += parseFloat(element.porcentaje);
          }
        });
      } else {
        this.csContables.forEach((element) => {
          sumaPorcentaje += parseFloat(element.porcentaje);
        });
      }
      let total_presupuesto =
        sumaPorcentaje + parseFloat(this.contable.porcentaje);
      if (total_presupuesto > 100) {
        me.$swal({
          icon: "error",
          title: "Ocurrió un error... El porcentaje no puede ser mayor al 100%",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        this.csContables[index] = this.contable;
        this.estado_contable = -1;
        this.id_actualizar_contable = -1;
        await me.calcularContable();
        await me.saveContableTotal();
      }
    },

    async saveContableTotal() {
      axios({
        method: "POST",
        url: "/api/cs/servicios/contable",
        data: {
          csContables: this.csContables,
          cs_servicio_interno_id: this.$parent.servicioInterno.id,
        },
      })
        .then(async (response) => {
          //await this.getIndex();
        })
        .catch((e) => {
          this.message += e.message;
        });
    },

    async cambiarAsignaciones() {
      this.$parent.cargando = true;
      if (this.asignacionContable) {
        await axios
          .get("/api/cs/servicios/contable/asignacionesContables", {
            params: {
              cs_asignacion_contable_id: this.asignacionContable.id,
            },
          })
          .then((response) => {
            if (response.data) {
              let arrayAsignacionContable = [];
              response.data.forEach((data) => {
                /* if (data.responsable) {
                  data.responsable = data.responsable
                    ? `${data.responsable.nombres} ${data.responsable.apellidos}`
                    : null;
                } */
                arrayAsignacionContable.push({
                  id: null,
                  cs_servicio_interno_id: this.$parent.servicioInterno.id,
                  tipo_presupuesto: data.tipo_presupuesto,
                  ceco_afe_id: data.contrato_contable.id,
                  codigo_ceco_afe: data.contrato_contable.codigo_contable,
                  porcentaje: parseFloat(data.porcentaje),
                  nTipoPresupuesto: data.nTipoPresupuesto,
                  ceco_afe: data.contrato_contable,
                  tipoPresupuesto: this.listasForms.tipo_presupuesto.find(
                    (ceaf) => ceaf.numeracion === data.tipo_presupuesto
                  ),
                  responsable: data.responsable,
                  nResponsable: data.responsable.nombre,
                  responsable_id: data.responsable.id,
                  imputacion: data.asignacion_contable.nombre,
                  cs_asignacion_contable_id: data.asignacion_contable.id,
                  area: data.area,
                  nArea: data.nArea,
                  nActivoProduccion: data.nActivoProduccion,
                  activo_produccion: data.activo_produccion,
                });
              });
              this.csContables = arrayAsignacionContable;
              this.calcularContable();
            }
          });
      }
      this.$parent.cargando = false;
      await this.saveContableTotal();
    },

    async calcularContable() {
      let totalPresupuesto = 0;
      this.csContables.forEach((element) => {
        totalPresupuesto += parseFloat(element.porcentaje);
      });
      this.totalPresupuesto = totalPresupuesto;
      return totalPresupuesto;
    },
  },
};
</script>
